import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

// returned array of arrays based only on prismic returned data
export const flattenArr = (arr) => {
  const allCategory = [].concat.apply([], arr);
  // remove null or undefined value from prismic
  const filterNull = allCategory.filter((item) => item);
  // filter out repeated value
  const removedRepeatedValue = filterNull
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .sort();

  // final clean array to use for categories
  return removedRepeatedValue;
};

export function formatDate(str) {
  dayjs.extend(advancedFormat);
  return dayjs(str).format("Do MMMM YYYY");
}
