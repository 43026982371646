// The pagination of the blog list is all handled by just Array.slice
// Since this is a static website

import React, { useState, useEffect, useRef } from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import styled, { css } from "styled-components";
import { flattenArr, formatDate } from "../components/helpers";
import { graphql, Link } from "gatsby";
import {
  mainWhite,
  mainRed,
  navyBlue,
  transHover,
  aku,
  innerWidth,
  gotham,
  gothamBold,
  screen,
} from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { scaleIn } from "../components/common/animations";
import { toSlug } from "../components/common/functions";
import Arrow from "../images/svg/right-arrow.svg";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
    padding-bottom: 120px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 180px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    @media ${screen.small} {
      font-size: 2.7rem;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .description {
    margin-top: 30px;
    max-width: 1000px;
    p {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1.1rem;
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
    strong {
      font-family: ${gothamBold};
    }
  }

  .filter-section {
    margin-top: 30px;
    @media ${screen.small} {
      margin-top: 35px;
    }
    @media ${screen.medium} {
      margin-top: 60px;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${gothamBold};
      font-size: 1.3rem;
    }

    &__filter-list {
      display: flex;
      flex-wrap: wrap;
      max-width: 1000px;
      margin-top: 20px;

      li {
        font-family: ${gotham};
        font-size: 1.05em;
        margin-right: 10px;
        margin-bottom: 15px;
        @media ${screen.small} {
          font-size: 1.25rem;
          margin-right: 22px;
          margin-bottom: 20px;
        }
        @media ${screen.medium} {
          font-size: 1.42rem;
          margin-right: 20px;
          margin-bottom: 18px;
        }

        .category {
          color: ${navyBlue};
          cursor: pointer;
          transition: ${transHover};

          &:hover {
            color: ${mainRed};
          }

          &--active {
            color: ${mainRed};
          }
        }

        .slash {
          color: ${navyBlue};
          padding-left: 5px;
          @media ${screen.small} {
            padding-left: 15px;
          }
        }

        &:last-child {
          .slash {
            visibility: hidden;
          }
        }
      }
    }
  }

  .date-section {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    &__page-guide {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1rem;
      visibility: hidden;
      align-self: center;
      display: none;
      @media ${screen.small} {
        display: block;
      }
      @media ${screen.large} {
        visibility: visible;
      }
    }

    &__sort {
      display: flex;
      align-items: center;

      p {
        color: ${navyBlue};
        font-family: ${gotham};
        font-size: 1.1rem;
        margin-right: 20px;
      }

      .select {
        border: 1px ${navyBlue} solid;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 14px;
        position: relative;

        span {
          color: ${navyBlue};
          font-family: ${gotham};
        }

        svg {
          width: 12px;
          margin-left: 10px;
          transition: ${transHover};
        }

        .options {
          position: absolute;
          border-left: 1px ${navyBlue} solid;
          border-right: 1px ${navyBlue} solid;
          border-bottom: 1px ${navyBlue} solid;
          top: 39px;
          background: ${mainWhite};
          z-index: 5;
          width: calc(100% + 2px);
          padding: 10px 14px;
          margin-left: -15px;

          span {
            color: ${navyBlue};
            display: block;
            padding: 7px 0;
            transition: ${transHover};
            &:hover {
              color: ${mainRed};
            }
          }
        }
      }
    }
  }

  .blogs-container {
    margin-top: 5px;

    &__blog-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;

      .blog-item {
        overflow: hidden;
        position: relative;
        margin: 20px 0 35px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        animation: ${(props) =>
          props.scaleIn
            ? css`
                ${scaleIn} 0.15s ease-in-out
              `
            : `none`};
        @media ${screen.small} {
          width: 48%;
          margin: 20px 0 70px 0;
        }
        @media ${screen.medium} {
          width: 31.4%;
        }
        @media ${screen.large} {
          width: 32.5%;
        }

        &__featured-img {
          position: relative;
          cursor: pointer;
          width: 100%;

          @media ${screen.withCursor} {
            &::after {
              content: "";
              background: rgba(200, 29, 41, 0.9);
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              transition: ${transHover};
              opacity: 0;
            }
          }

          &:hover ::after {
            opacity: 1;
          }
        }

        &__title {
          color: ${navyBlue};
          font-family: ${gothamBold};
          font-size: 1.2rem;
          max-width: 440px;
          margin-top: 10px;
          @media ${screen.small} {
            margin-top: 20px;
            font-size: 1.3rem;
          }

          @media ${screen.large} {
            margin-top: 25px;
          }
        }

        &__date {
          color: ${mainRed};
          font-family: ${gotham};
          font-size: 1rem;
          margin: 3px 0;
          @media ${screen.small} {
            font-size: 1.1rem;
            margin: 6px 0;
          }

          span {
            cursor: pointer;
          }
        }

        &__description {
          color: ${navyBlue};
          font-size: 1.1rem;
          font-family: ${gotham};
          max-width: 470px;
          margin-bottom: 20px;
          @media ${screen.small} {
            margin-bottom: 30px;
          }
        }

        &__link {
          background: ${navyBlue};
          display: table;
          color: ${mainWhite};
          font-family: ${gotham};
          font-size: 1.1rem;
          padding: 12px 30px;
          transition: ${transHover};
          margin-top: 0;
          @media ${screen.small} {
            margin-top: auto;
          }
          &:hover {
            background: ${mainRed};
          }
        }
      }

      &:after {
        content: " ";
        visibility: hidden;
        width: 100%;
        @media ${screen.small} {
          width: 49%;
        }
        @media ${screen.medium} {
          width: 32.5%;
        }
      }
    }

    &__paginate {
      display: flex;
      margin-top: 20px;

      .box {
        display: block;
        cursor: pointer;
        width: 38px;
        height: 40px;
        border: 1px ${navyBlue} solid;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: ${transHover};

        &:hover {
          border: 1px ${mainRed} solid;
        }

        &:hover .body {
          fill: ${mainRed};
          transition: ${transHover};
        }

        &:hover .page-num {
          color: ${mainRed};
          transition: ${transHover};
        }

        .page-num {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 1.1rem;
        }

        &--active {
          background: ${mainRed};
          border: 1px ${mainRed} solid;
          pointer-events: none;
          &:hover .page-num {
            color: ${mainWhite};
          }
          .page-num {
            color: ${mainWhite};
          }
        }
      }
    }
    &__page-guide {
      color: ${navyBlue};
      font-family: ${gotham};
      align-self: center;
      font-size: 0.85rem;
      margin-top: 5px;
      @media ${screen.large} {
        display: none;
      }
    }
  }
`;

const NewsPage = ({ data }) => {
  // get all categories from prismic blog list
  const getCategories = data.blogs.edges.map((item) => {
    return item.node.data.categories.map(
      (each) => each.category.document && each.category.document.data.title.text
    );
  });

  // array of categories to use for filtering
  const allCategories = flattenArr(getCategories);

  // number length of how many news will be displayed per page
  // desktop and tablet/mobile have different length
  // 4 for tablet/mobile
  // 9 for desktop
  const [num, setNum] = useState(
    typeof window !== "undefined" && window.innerWidth < 1160 ? 4 : 9
  );

  // starter page length
  const [eachPageLength, setPageLength] = useState(9);
  // starter page length from
  const [paginateFrom, setPaginateFrom] = useState(0);
  // starter page length to
  const [paginateTo, setPaginateTo] = useState(num);
  // current page selected
  const [currentPage, setCurrentPage] = useState(1);
  // all blogs from Prismic
  const [blogList, setBlogList] = useState(data.blogs.edges);
  // selected category
  const [selectedCategory, setSelectedCategory] = useState("All");
  // filter date state
  const [oldToNewFilter, setOldToNewFilter] = useState(true);
  // date sorter dropdown state
  const [sortDateOpen, setSortDate] = useState(false);
  // animate blog list only if user start filtering blogs
  const [triggerAnimation, setAnimation] = useState(false);

  const list = useRef(null);

  // count the child element of the blog list
  useEffect(() => {
    setPageLength(list.current.childElementCount);
  });

  // screen resize listener
  useEffect(() => {
    const CheckBrowserWidth = () => {
      if (window.innerWidth > 1160) {
        setNum(9);
      } else {
        setNum(4);
      }
    };
    window.addEventListener("resize", CheckBrowserWidth);

    return () => {
      window.removeEventListener("resize", CheckBrowserWidth);
    };
  }, [num]);

  // watcher when user select a category
  useEffect(() => {
    if (selectedCategory === `All`) {
      setBlogList(data.blogs.edges);
    } else {
      const filtered = data.blogs.edges.filter((blog) => {
        return blog.node.data.categories.some(
          (cat) =>
            cat.category.document &&
            cat.category.document.data.title.text === selectedCategory
        );
      });
      setBlogList(filtered);
    }
    setCurrentPage(1);
  }, [selectedCategory]);

  // Prismic content
  const pageTitle = data.content.data.page
    ? data.content.data.page.text
    : `News`;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `News`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `News`;

  // better structure the array of each blog object
  // filter the blogs by date if user toggle to "old to new" or "new to old" dropdown
  // sorting the date by just swapping over the exact same array just with the different date order
  const allBlogs = oldToNewFilter
    ? blogList
        .map((blog) => {
          return {
            id: blog.node.id,
            title: blog.node.data.title.text,
            image:
              blog.node.data.blog_featured_image.thumbnails &&
              blog.node.data.blog_featured_image.thumbnails.thumbnail
                .gatsbyImageData,
            alt: blog.node.data.blog_featured_image.alt,
            description: blog.node.data.featured_description,
            category: blog.node.data.categories.map(
              (item) =>
                item.category.document && item.category.document.data.title.text
            ),
            slug: toSlug(blog.node.data.title.text),
            date: blog.node.data.edit_published_date
              ? blog.node.data.edit_published_date
              : blog.node.first_publication_date,
            dateForSort: blog.node.data.edit_published_date
              ? Number(blog.node.data.edit_published_date.split("-").join(""))
              : Number(
                  blog.node.first_publication_date
                    .slice(0, 10)
                    .split("-")
                    .join("")
                ),
          };
        })
        // removed the full content blog from Prismic for dev purpose only
        .filter((blog) => `Prismic__Blog__XVDg4REAACIAgtME` !== blog.id)
        .sort((a, b) => b.dateForSort - a.dateForSort)
    : blogList
        .map((blog) => {
          return {
            id: blog.node.id,
            title: blog.node.data.title.text,
            image:
              blog.node.data.blog_featured_image.thumbnails &&
              blog.node.data.blog_featured_image.thumbnails.thumbnail
                .gatsbyImageData,
            alt: blog.node.data.blog_featured_image.alt,
            description: blog.node.data.featured_description,
            category: blog.node.data.categories.map(
              (item) =>
                item.category.document && item.category.document.data.title.text
            ),
            slug: toSlug(blog.node.data.title.text),
            date: blog.node.data.edit_published_date
              ? blog.node.data.edit_published_date
              : blog.node.first_publication_date,
            dateForSort: blog.node.data.edit_published_date
              ? Number(blog.node.data.edit_published_date.split("-").join(""))
              : Number(
                  blog.node.first_publication_date
                    .slice(0, 10)
                    .split("-")
                    .join("")
                ),
          };
        })
        // removed the full content blog from Prismic for dev purpose only
        .filter((blog) => `Prismic__Blog__XVDg4REAACIAgtME` !== blog.id)
        .sort((a, b) => a.dateForSort - b.dateForSort);

  // get the pagination length of all the blogs
  const blogsPage = new Array(Math.ceil(allBlogs.length / num)).fill(" ");

  // blogs to render by slice the array from its index
  const renderBlogs = allBlogs.slice(paginateFrom, paginateTo);

  // hide/show date sorter dropdown
  const toggleSortDate = () => {
    setSortDate(!sortDateOpen);
  };

  // sort blog list new to old
  const sortNewToOld = () => {
    setOldToNewFilter(true);
    toggleSortDate();
    if (!triggerAnimation) {
      setAnimation(true);
    }
  };

  // sort blog list old to new
  const sortOldToNew = () => {
    setOldToNewFilter(false);
    toggleSortDate();
    if (!triggerAnimation) {
      setAnimation(true);
    }
  };

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper scaleIn={triggerAnimation} id="paginate">
        <h2 className="heading">
          <Arrow />
          {pageTitle}
        </h2>
        <div className="filter-section">
          <ul className="filter-section__filter-list">
            <li>
              <span
                className={
                  selectedCategory === `All`
                    ? `category category--active`
                    : `category`
                }
                onClick={() => {
                  if (selectedCategory !== `All`) {
                    setSelectedCategory(`All`);
                    setPaginateFrom(0);
                    setPaginateTo(num);

                    if (!triggerAnimation) {
                      setAnimation(true);
                    }
                  }
                }}
              >
                All
              </span>{" "}
              <span className="slash"> / </span>
            </li>
            {allCategories.map((category) => (
              <li key={category}>
                <span
                  className={
                    selectedCategory === category
                      ? `category category--active`
                      : `category`
                  }
                  onClick={() => {
                    if (selectedCategory !== category) {
                      setSelectedCategory(category);
                      setPaginateFrom(0);
                      setPaginateTo(num);

                      if (!triggerAnimation) {
                        setAnimation(true);
                      }
                    }
                  }}
                >
                  {category}
                </span>
                <span className="slash"> / </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="date-section">
          <p className="date-section__page-guide">
            Showing {paginateFrom + 1} - {paginateFrom + eachPageLength} of{" "}
            {allBlogs.length} News Posts
          </p>
          <div className="date-section__sort">
            <p>Sort by: </p>
            <div className="select" onClick={toggleSortDate}>
              <span>Date, {oldToNewFilter ? `New to Old` : `Old to New`}</span>
              <Arrow
                style={{
                  transform: sortDateOpen ? `rotate(89deg)` : `rotate(0deg)`,
                }}
              />
              {sortDateOpen && (
                <div className="options">
                  <span onClick={sortNewToOld}>Date, New to Old</span>
                  <span onClick={sortOldToNew}>Date, Old to New</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="blogs-container">
          <ul className="blogs-container__blog-list" ref={list}>
            {renderBlogs.map((item) => (
              <li className="blog-item" key={item.id}>
                <Link
                  className="blog-item__featured-img"
                  to={`/news/${item.slug}`}
                >
                  {item.image && (
                    <GatsbyImage
                      image={item.image}
                      alt={item.alt || "Featured image"}
                    />
                  )}
                </Link>
                <Link to={`/news/${item.slug}`}>
                  <h3 className="blog-item__title">{item.title}</h3>
                </Link>

                <p className="blog-item__date">
                  {formatDate(item.date)}

                  {item.category.map((cat, i) => (
                    <span key={i} onClick={() => setSelectedCategory(cat)}>
                      {cat && ` / ${cat}`}
                    </span>
                  ))}
                </p>
                <p className="blog-item__description">{item.description}</p>
                <Link className="blog-item__link" to={`/news/${item.slug}`}>
                  Read More
                </Link>
              </li>
            ))}
          </ul>
          <div className="blogs-container__paginate">
            {blogsPage.map((empty, i) => (
              <span
                className={currentPage === i + 1 ? `box box--active` : `box`}
                key={i + 1}
                onClick={() => {
                  setPaginateFrom(num * i);
                  setPaginateTo(num * (i + 1));
                  setCurrentPage(i + 1);
                  window.scroll(0, 0);
                  if (!triggerAnimation) {
                    setAnimation(true);
                  }
                }}
              >
                <span className="page-num">{i + 1}</span>
              </span>
            ))}
          </div>
          <p className="blogs-container__page-guide">
            Showing {paginateFrom + 1} - {paginateFrom + eachPageLength} of{" "}
            {allBlogs.length} News Posts
          </p>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NewsPage;

export const dataQuery = graphql`
  {
    content: prismicNewsPage {
      data {
        title_tag
        meta_description
        page {
          text
        }
      }
    }
    blogs: allPrismicBlog {
      edges {
        node {
          first_publication_date
          id
          data {
            title {
              text
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    id
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            edit_published_date
            blog_featured_image {
              alt
              thumbnails {
                thumbnail {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            featured_description
          }
        }
      }
    }
  }
`;
